<template>
  <section style="height: 100%">
    <div class="container">
      <!-- <el-aside style="padding-right: 20px;border-right:1px solid #e9eaf2;width: 150px;">
        <h1>账号状态</h1>
        <el-tree
          style="margin-top: 10px;margin-bottom:10px;"
          :data="status"
          node-key="id"
          icon-class="el-icon-s-flag"
          default-expand-all
          :expand-on-click-node="false"
          :highlight-current="true"
          :current-node-key="treeCurrentNode"
        >
          <span
            class="custom-tree-node"
            slot-scope="{ node, data }"
            @click="showTreeWorker(data.id, data.pid)"
          >
            <span>
              {{ data.label }}
            </span>
          </span>
        </el-tree>
      </el-aside> -->
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>内容账号</h1></div>
          <div class="control-box" style="margin-top: 10px;">
            <el-select v-model="params.province_id" placeholder="请选择省份" style="margin-right:10px;margin-top: 10px;" clearable @change="provinceClick()">
              <el-option
                v-for="item in provinceList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.city_id" placeholder="请选择市" clearable style="margin-right:10px;margin-top: 10px;" @change="cityClick()">
              <el-option
                v-for="item in cityList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.area_id" placeholder="请选择地区" clearable style="margin-right:10px;margin-top: 10px;" @change="areaClick()">
              <el-option
                v-for="item in areaList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-time-select
              placeholder="起始时间"
              v-model="params.start_time"
              style="margin-right:10px;width:217px;margin-top: 10px;"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }">
            </el-time-select>
            <el-time-select
              placeholder="结束时间"
              style="margin-right:10px;width:217px;margin-top: 10px;"
              v-model="params.end_time"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30',
                minTime: params.start_time
              }">
            </el-time-select>
            <el-button icon="el-icon-search" @click="getTableData" style="margin-top: 10px;">搜索</el-button>
            <el-button icon="el-icon-plus" type="primary" @click="handleOpenAdd" style="margin-top: 10px;" v-if="admin_permission.indexOf('add')>-1">添加内容</el-button>
          </div>
        </div>
        <div class="table">
          
      <!-- 轮播列表 -->
          <el-table :data="table" style="width: 100%" class="table" :height="height" v-if="height>0">
            <!-- 数据展示区 -->
            <el-table-column label="内容名称" prop="name" align="left">
            </el-table-column>
            <el-table-column label="类型" prop="type" align="center" width="70px">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.type === 0">图片</el-tag>
                <el-tag v-if="scope.row.type === 1" style="background-color: rgba(0,0,0,0.8);color:#fff;border:none;">视频</el-tag>
              </template>
            </el-table-column>
            <!-- <el-table-column label="用途" prop="attribute" align="center" width="70px">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.type === 0" effect="plain" type="danger">政务</el-tag>
                <el-tag v-if="scope.row.type === 1" effect="plain">商务</el-tag>
              </template>
            </el-table-column> -->
            <el-table-column label="区域" prop="src" align="center">
              <template slot-scope="scope">
                <span>{{scope.row.province_name}}{{scope.row.city_name}}{{scope.row.area_name}}</span>
              </template>
            </el-table-column>
            <el-table-column label="图片/视频" prop="start_date" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.src==''">暂无图片</span>
                <div v-else>
                  <img v-if="scope.row.type===0" style="width: 100px;height:100px;" :src="scope.row.src" @click="imgClick(scope.row.src)">
                  <video v-if="scope.row.type===1" :src="scope.row.src" style="width: 100px;height:100px;" controls="controls" :poster="scope.row.poster"></video>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="小设备图片" prop="start_date" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.min_src==''">暂无图片</span>
                <div v-else>
                  <img v-if="scope.row.type===0" style="width: 100px;height:100px;" :src="scope.row.min_src" @click="imgClick2(scope.row.min_src)">
                  <video v-if="scope.row.type===1" :src="scope.row.min_src" style="width: 100px;height:100px;" controls="controls" :poster="scope.row.min_poster"></video>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="日期" prop="start_date" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.start_date!=null">{{scope.row.start_date}}至{{scope.row.end_date}}</span>
                <span v-else>未设置日期</span>
              </template>
            </el-table-column>
            <el-table-column label="时间段" prop="start_time" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.start_time!=null">{{scope.row.start_time}}-{{scope.row.end_time}}</span>
                <span v-else>未设置时间</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="状态" prop="status" align="center">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.type === 0" type="warning">未审核</el-tag>
                <el-tag v-if="scope.row.type === 1" type="success">审核通过</el-tag>
                <el-tag v-if="scope.row.type === 2" type="info">已拒绝</el-tag>
              </template>
            </el-table-column> -->
            <el-table-column label="操作" fixed="right" align="center" width="70">
              <template slot-scope="scope">
                <div
                  style="
                      width: 100%;
                      display: inline-flex;
                      justify-content: space-between;
                    "
                >
                  <el-link style="color: #409eff;" @click="editTable(scope.row.id)" v-if="admin_permission.indexOf('update')>-1">
                    <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                      <i class="el-icon-edit" style="font-size:16px;"></i>
                    </el-tooltip>
                  </el-link>
                  <el-link style="color: #409eff;" @click="handleDelete(scope.row.id)" v-if="admin_permission.indexOf('deleted')>-1">
                    <el-tooltip class="item" effect="dark" content="刪除" placement="top-start">
                    <i class="el-icon-delete" style="font-size:16px;"></i>
                    </el-tooltip>
                  </el-link>
                
                </div>
              </template>
            </el-table-column>
          </el-table>
          
            <!-- <el-row :gutter="5" style="width: 100%;">
              <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="4" v-for="(value,key) in table" style="margin-bottom:10px;">
                <el-card :body-style="{ padding: '0px' }" style="position: relative;">
                  <img v-if="value.type===0" :src="value.src" style="width: 100%;">
                  <video v-if="value.type===1" :src="value.src" style="width: 100%;height:100%;" controls="controls"></video>
                  <el-tag type="" style="position:absolute;top:0;right:0" size="mini" effect="dark" v-if="value.status===0">审核中</el-tag>
                  <el-tag type="success" style="position:absolute;top:0;right:0" size="mini" effect="dark" v-if="value.status===1">审核通过</el-tag>
                  <el-tag type="info" style="position:absolute;top:0;right:0" size="mini" effect="dark" v-if="value.status===2">不通过</el-tag>
                  <div style="padding: 2px 8px; position: relative;">
                    <div style="font-weight:bold;font-size:14px;width: 100%;">
                      {{value.name}}
                    </div>
                    <div style="font-size: 12px;width: 100%;margin-top:5px;">
                      区域: {{value.province_name}} {{value.city_name}} {{value.area_name}}
                    </div>
                    <div style="font-size: 12px;width: 100%;margin-top:2px;">
                      时间段: {{value.start_time}} 至 {{value.end_time}}
                    </div>
                    <div class="bottom clearfix" style="position: absolute;top:0px;right:5px;">
                      <el-dropdown @command="command($event,value.id)">
                        <span class="el-dropdown-link" style="color: #409EFF">
                          操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="edit">修改</el-dropdown-item>
                          <el-dropdown-item command="apply" devided :disabled="value.status!==0">审核通过</el-dropdown-item>
                          <el-dropdown-item command="refuse" :disabled="value.status!==0">审核失败</el-dropdown-item>
                          <el-dropdown-item command="delete" devided>删除</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>
                </el-card>
              </el-col>
              
            </el-row> -->
          
        </div>
        
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next,jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </div>

    <el-dialog  v-if="dialogTableVisible" :visible.sync="dialogTableVisible">
      <img :src="img" alt="" style=" width:50%;margin-left:25%;">
    </el-dialog>
    <el-dialog  v-if="dialogTableVisible2" :visible.sync="dialogTableVisible2">
      <img :src="img2" alt="" style=" width:50%;margin-left:25%;">
    </el-dialog>
    <el-drawer
      title="更改媒体内容"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <edit-comp v-if="editDrawer" @close="handleClose" @success="handleDrawerSuccess" :id="editID" ref="newForm"></edit-comp>
    </el-drawer>

    <!-- 添加规则 -->
    <el-drawer
      title="添加媒体内容"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <add-comp
        @close="handleClose"
        @success="handleDrawerSuccess"
        @clear="clearContent"
        ref="newForm"
        v-if="addDrawer"
      ></add-comp>
    </el-drawer>
  </section>
</template>

<script>
  import { getList, addData,updateData,apply,refuse,deleteData} from "@/api/media.js";

  import { getProvince,getCity,getArea} from "@/api/public.js";
  import addComp from "./components/add.vue";
  import editComp from "./components/edit.vue";

  let page_name = 'media_management'
  export default {
    name: "App",
    components:{addComp,editComp},
    data() {
      return {
        dialogTableVisible:false,
        dialogTableVisible2:false,
        admin_permission:[],
        status: [
          {id:"",label:"全部"},
          {id:0,label:"审核中"},
          {id:1,label:"审核通过"},
          {id:2,label:"审核拒绝"},
        ],
        height:0,
        treeCurrentNode:"",
        provinceList:[],
        cityList:[],
        areaList:[],
        province_id:"",
        city_id:"",
        params: {
          page: 1,
          page_count: 10,
          province_id:'',
          city_id:'',
          area_id:'',
          start_time: '',
          end_time: '',
        },

        table:[],
        img:'',
        img2:'',
        service: [],
        editID: '',
        total: 0,
        outerVisible: false,
        innerVisible: false,
        addDrawer: false,
        editDrawer: false,
        direction: "rtl",
        addTradeParams: {
          name: "",
          parent_id: 0
        },
        visible: false,
        scopeForm:{
          id:'',
          sort: ''
        }
      };
    },

    methods: {
      getTableData() {
        getList(this.params).then(res=>{
          if(res.code === 200){
            this.table = res.data.data
            this.params.page = res.data.current_page
            this.params.page_count = res.data.per_page
            this.total = res.data.total
          }
        })
      },

      provinceClick(){
        let that = this
        console.log(that.params.province_id)
        if(that.params.province_id==''){
          that.cityList = []
          that.areaList = []
          that.params.city_id = ''
          that.params.area_id = ''
          return false
        }
        this.provinceList.forEach(item => {
          if(item.id == that.params.province_id ){
            that.province_id = item.province_id
            // console.log(that.provinceList)
          }
        });
        getCity(that.province_id).then(res=>{
          if(res.code === 200){
            // console.log(res.data)
            this.cityList = res.data
          }
        })
      },
      cityClick(){
        let that = this
        if(that.params.city_id==''){
          that.areaList = []
          that.params.area_id = ''
          return false
        }
        this.cityList.forEach(item => {
          if(item.id == that.params.city_id ){
            that.city_id = item.city_id
            console.log(that.city_id)
          }
        });
        getArea(that.city_id).then(res=>{
          if(res.code === 200){
            console.log(res.data)
            this.areaList = res.data
          }
        })
      },
      areaClick(){
        let that = this
        if(that.params.area_id==''){
          return false
        }
        this.areaList.forEach(item => {
          if(item.id == that.params.area_id ){
            that.area_id = item.area_id
            console.log(that.area_id)
          }
        });
      },
      command(e,id){
        console.log(31431)
        if(e === 'edit'){
          this.editTable(id)
        }
        if(e === 'apply'){
          this.$confirm('此操作将审核通过, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            apply(id).then(res=>{
              if(res.code === 200){
                this.$message({
                  message: "操作成功",
                  type: "success"
                });
                this.getTableData();
              }
            })
          }).catch(() => {
          });
        }
        if(e === 'refuse'){
          this.$confirm('此操作将审核失败, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            refuse(id).then(res=>{
              if(res.code === 200){
                this.$message({
                  message: "操作成功",
                  type: "success"
                });
                this.getTableData();
              }
            })
          }).catch(() => {
          });
        }
        if(e === 'delete'){
          this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
            .then(_ => {
              deleteData(id).then(res=>{
                this.getTableData()
                this.$notify({
                  title: '成功',
                  message: '删除成功',
                  type: 'success'
                });
              })
            })
            .catch(_ => {});
        }
      },

      writeScope(id){
        console.log(id)
        this.scopeForm.id = id
        let tmp = [];
        this.service.forEach((value,key)=>{
          tmp[key] = value
          if (id === value.id){
            this.scopeForm.sort = parseInt(value.sort)
            tmp[key].write_scope = true
          }
        })
        this.service = tmp;
      },
      imgClick(e){
        this.img = e
        this.dialogTableVisible =true
      },
      imgClick2(e){
        this.img2 = e
        this.dialogTableVisible2 =true
      },
      scopeChange(){
        editData(this.scopeForm.id,{sort:this.scopeForm.sort}).then(res=>{
          if (res.code === 200){
            this.searchSet()
          }
        })
      },

      //选择TREE
      showTreeWorker(id, pid) {
          this.params.status = id;
          this.getTableData();
      },

      // 删除
      handleDelete(id) {
        this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
          .then(_ => {
            deleteData(id).then(res=>{
              this.getTableData()
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
              });
            })
          })
          .catch(_ => {});
      },
      // 打开添加
      handleOpenAdd() {
        this.addDrawer = true;
      },
      // 关闭添加/修改
      handleClose() {
        this.$confirm("确认关闭？")
          .then((_) => {
            this.addDrawer = false;
            this.editDrawer = false;
          })
          .catch((_) => {});
      },

      editTable(id){
        this.editID = id
        this.editDrawer = true
      },
      // 添加/修改成功
      handleDrawerSuccess() {
        this.editDrawer = false
        this.addDrawer = false
        this.getTableData()
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 清空子组件
      clearContent() {
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 分页
      prevpage() {
        this.params.page -= 1;
        this.getTableData();
      },
      nextpage() {
        this.params.page += 1;
        this.getTableData();
      },
      handleCurrentChange(val) {
        this.params.page = val;
        this.getTableData();
      },
    },
    mounted() {
      this.admin_permission = this.$store.state.permission.permissionArr[page_name]
      console.log(this.admin_permission)
      this.getTableData();
      getProvince().then(res=>{
        if(res.code === 200){
          this.provinceList = res.data
        }
      })
      if(document.body.clientHeight&&document.documentElement.clientHeight)
      {
        var clientHeight = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
      }
      else
      {
        var clientHeight = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
      }
        console.log(clientHeight)
        this.height = clientHeight-82-60-40-20-20-40
      }
  };
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;

    .table {
      margin-top: 24px !important;
    }

    .toolbar {
      background: #fff;
      width: 100%;
      padding: 16px 32px 26px 0;
    }
  }

  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>
<style>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    padding-right: 8px;
  }
  .el-tree-node__expand-icon.is-leaf{
    color: #c0c4cc;
  }
</style>
